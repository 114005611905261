import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Logo from '../../images/logo.svg'
import mp3_file from '../../../static/heb_lief.mp3'

import './header.scss'

const Header = ({ heading, tagline }) => (
  <header id="header" className="header">
    <div className="container">
      <div className="relative lg:max-w-3xl mx-auto my-0 mb-10 md:my-10">
        <div className="artifact"></div>
        <Link
          to="/"
          title={heading}
          className="logo inline-block mt-10 md:mt-0"
        >
          <Logo />
        </Link>
        <div className="tagline relative flex flex-col md:flex-row px-10 md:px-0 items-center">
          {tagline}
          <audio
            src={mp3_file}
            controls
            className="ml-0 mt-2 md:ml-5 md:mt-0"
          />
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  heading: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
}

export default Header
