import React from 'react'

import './footer.scss'

const Footer = () => (
  <footer id="footer" className="footer relative w-screen">
    <div className="container">
      <div className="relative lg:max-w-3xl mx-auto">
        <div className="artifact"></div>
        <ul className="links block lg:flex lg:flex-wrap lg:justify-end lg:space-x-20 lg:w-3/4 ml-auto py-10 bg-gray">
          <li className="my-2">
            &copy; {new Date().getFullYear()} Heblief, Inc.
          </li>
          {/* <li className="my-2">
            <a
              href="https://www.otherfox.com"
              target="_blank"
              rel="noopener noreferrer"
              className="uppercase"
            >
              otherfox.com
            </a>
          </li>
          <li className="my-2">
            <a
              href="http://www.nicksbread.com"
              target="_blank"
              rel="noopener noreferrer"
              className="uppercase"
            >
              nicksbread.com
            </a>
          </li>
          <li className="my-2">
            <a
              href="https://www.rubyfranklin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="uppercase"
            >
              rubyfranklin.com
            </a>
          </li>
          <li className="my-2">
            <a
              href="https://www.vegetarianfork.com"
              target="_blank"
              rel="noopener noreferrer"
              className="uppercase"
            >
              vegetarianfork.com
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
