import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import Header from 'components/header'
import Intro from 'components/intro'
import Footer from 'components/footer'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div className="relative flex flex-col content-center justify-center min-h-screen">
        <Header
          heading={data.homeJson.heading}
          tagline={data.homeJson.tagline}
        />
        <main className="main">
          <Intro content={data.homeJson.intro.childMarkdownRemark.html} />
        </main>
        <Footer />
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    homeJson {
      title
      heading
      tagline
      intro {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
