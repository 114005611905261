import React from 'react'
import PropTypes from 'prop-types'

import './intro.scss'

const Intro = ({ heading, content }) => (
  <section id="intro" className="intro container">
    {heading && <h1 className="mb-20">{heading}</h1>}
    <div className="relative lg:max-w-3xl mx-auto pb-5 pl-5 lg:pl-0">
      <article
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <div className="artifact"></div>
    </div>
  </section>
)

Intro.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Intro
